.Sign-FootContent {
	width: 100%;
	height: 66px;
	bottom: 0;
	left: 0;
	right: 0;
	background-color: #414141;
	position: absolute;
	color: #fff;
	.InnerFoot {
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 100%;
	}
	.InnerFootLeft {
		display: flex;
		align-items: center;
		height: 100%;
		background-color: #2b2b2b;
		padding-left: 13px;
		padding-right: 13px;
	}
	.CyberjuangLogoSection {
		overflow: hidden;
		white-space: nowrap;
		position: relative;
		display: flex;
		align-items: center;
		.logo {
			max-width: 40px;
			width: 40px;
		}
		ul.TextWrapper {
			margin: 0;
			padding-left: 8px;
			li {
				font-size: 0.93em;
				font-weight: 700;
				line-height: 1.55em;
			}
		}
	}
}
