@media all {
  .page-break {
    display: none;
  }
}
 
@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    font-size: 12.4px;
    font-family: "Roboto", Arial, sans-serif;
    -webkit-print-color-adjust: exact;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    line-height: 1.75;
    color: #444;
    background-color: #fff;
    margin: 0;
  }

  table,
  .paslonName {
    font-size: 8px;
    color: #414141!important;
  }
}
 
@media print {
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-before: auto;
  }
}
 
@page {
  size: landscape;
  margin: 10mm;
}